<script setup lang="ts">
import { MediaImageFieldsFragment, ParagraphTextImage } from '~/api/graphql/generated'

const props = defineProps<{
  entity: ParagraphTextImage
}>()

const data = computed(() => ({
  title: props.entity.fieldTitle ?? '',
  description: props.entity?.fieldText?.processed ?? '',
  image: props.entity.fieldImage?.entity as MediaImageFieldsFragment,
}))
</script>

<template>
  <Section v-animate="{ cluster: true }">
    <TeaserSingle :data="data" />
  </Section>
</template>
